/* ===============================================================
    Forms
	- Global form styles and Bootstrap form overrides
   =============================================================== */

input[type='text'],
input[type='email'],
input[type='search'],
input[type='password'],
input[type='number'],
input[type='tel'],
select,
textarea,
.form-control {
    @extend .form-control;
    padding-left: 8px;
    padding-right: 8px;
    position: relative;
    z-index: 2;
}

label {
    font-family: $font-family-serif;
    font-weight: $font-weight-bold;
    font-size: 14px;
    margin-bottom: 2px;
}

.custom-control-label {
    line-height: 2.2;

    > div {
        line-height: $line-height-base;
    }
}

.custom-file {
    max-width: 400px;
}

.custom-control-label::before {
    border: $input-btn-border-width solid $input-border-color;
}

.custom-file-label,
.custom-file-label::after {
    line-height: 32px;
}

input[type='button'] {
    @extend .btn, .btn-primary;
}
.form-group {
    &.has-error {
        .form-control {
            @extend .is-invalid;
        }

        .help-block.error {
            @extend .invalid-feedback;
            margin-left: $input-btn-border-width;
            padding-left: $input-btn-padding-x;
            display: block;
        }
    }

    &.has-success {
        .form-control {
            @extend .is-valid;
        }
    }
}

.input-group {
    .form-control {
        border-right: none;
    }

    .show-password {
        background: transparent;
        border-left: none;
    }
}

.algolia-autocomplete {
    flex: 1 1 auto;
    width: 1%;
}

.algolia-autocomplete input.aa-input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.algolia-autocomplete .aa-input,
.algolia-autocomplete .aa-hint {
    width: 100%;
}
.algolia-autocomplete .aa-hint {
    color: #999;
}
.algolia-autocomplete .aa-dropdown-menu {
    width: 100%;
    @extend .dropdown-menu;
    display: block;

    .dropdown-item {
        font-size: 14px;
    }
}
.algolia-autocomplete .aa-dropdown-menu .aa-suggestion {
    cursor: pointer;
}
