// Account
.nav-tabs-responsive {
    overflow-x: auto;
    overflow-y: hidden;
}

.nav-tabs {
    // min-width: 600px;
    border-bottom: 0;

    .nav-link {
        color: $body-color;
        font-family: $font-family-serif;
        font-size: 14px;
        font-weight: $font-weight-normal;

        &.active {
            font-weight: $font-weight-bold;
        }
    }
}

.tab-content {
    border-radius: 0 $border-radius $border-radius $border-radius;
    border: 2px solid $primary;
    // border-left: 2px solid $primary;
    // border-right: 2px solid $primary;
    // border-bottom: 2px solid $primary;
    font-size: 14px;
    overflow: hidden;

    .label-mobile {
        font-weight: $font-weight-bold;
        font-family: $font-family-serif;
    }

    .tab-pane {
        .border-bottom.border-primary {
            border-width: 2px !important;

            &:last-child {
                border: none !important;
            }
        }

        > .row {
            padding-left: 16px;
            padding-right: 16px;
        }

        > .container {
            .border-top:first-child {
                border: none !important;
            }
        }
    }

    .btn {
        margin-top: 1rem;
    }

    table {
        margin-bottom: 0;

        th {
            border-top: 0;
            border-bottom: 1px solid $dark-imperial-blue;
        }

        // tfoot td {
        //     border-radius: 0 0 $border-radius $border-radius;
        // }
    }

    .pagination li {
        opacity: 0.25;

        &.active {
            opacity: 1;
        }
    }
}
