/* ==============================================================
   HEADER
   - Styles for anything that resides in the header
   ============================================================== */

#header {
    width: 100%;
    z-index: 999;
    position: relative;

    .navbar-collapse {
        justify-content: flex-end;
    }

    .navbar {
        &.main-nav {
            background: $white !important;
        }

        .is-admin & .container {
            max-width: 100%;
            padding-left: 16px;
            padding-right: 16px;
        }

        .h3 {
            font-weight: $font-weight-semibold;
            color: $body-color;
        }

        .user.dropdown {
            padding: 10px 0 10px 20px;
            border-left: 1px solid $gray-300;

            .svg-inline--fa {
                margin-left: 10px;
                transition: transform 0.2s ease;
            }

            .dropdown-menu {
                max-width: 120px;
                min-width: 8rem;
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                background: $dark-imperial-blue;
                border-radius: 0;
                font-size: 14px;

                .dropdown-item {
                    padding: 0.25rem 1rem;
                    color: $white;
                    border-radius: 0;
                }

                .dropdown-item:hover {
                    color: $dark-imperial-blue;
                }

                &::before {
                    content: '';
                    display: block;
                    width: 0;
                    height: 0;
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    border-bottom: 8px solid $dark-imperial-blue;
                    position: absolute;
                    top: -8px;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
            }

            &.show {
                .svg-inline--fa {
                    transform: rotate(-180deg);
                }
            }
        }
    }

    #main-nav {
        flex-grow: 0;

        .fa-shopping-cart {
            font-size: 24px;
            color: $white;
        }
    }

    .navbar-brand {
        svg {
            width: 193px;
            height: 61px;

            &.mobile-logo {
                width: 30px;
                height: 45px;
            }
        }
    }

    .search {
        z-index: 1003;

        .svg-inline--fa {
            margin-left: 6px;
            font-size: 15px;
        }

        .fa-times-circle {
            display: none;
        }

        &.show {
            .open-search {
                background: $secondary;
            }

            .fa-times-circle {
                display: inline-block;
            }

            .fa-search {
                display: none;
            }
        }

        #search-dropdown.dropdown-menu {
            min-width: 19rem;
            left: initial;
            right: 0;
            top: calc(100% - 3px);
            padding: 0;
            border-top-right-radius: 0;
            border-top-left-radius: 0;
            border: none;
            background: $secondary;
        }
    }

    .masquerade-bar {
        background: $warning;

        .btn {
            border-width: 1px;
        }
    }

    .suspended-bar {
        background: #DD3444;
    }
}
