/* ==============================================================
   FOOTER
   - Styles for anything that resides in the footer
   ============================================================== */

#footer {
    color: $white;

    .footer-bottom {
        padding: 16px 0 64px;
        background: $primary;
        font-size: 10px;
        text-align: center;

        .row > div {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        @include media-breakpoint-up(md) {
            text-align: left;

            .row > div {
                padding-top: 0;
                padding-bottom: 0;
            }

            .row > div:nth-child(2) {
                text-align: right;
            }
        }

        a {
            color: $white;
        }

        .name {
            display: block;

            @include media-breakpoint-up(xl) {
                display: inline;
                padding-right: 40px;
            }
        }

        .menu {
            margin: 0;
            padding: 0;

            li {
                display: inline-block;

                a {
                    font-size: 12px;
                    font-weight: $font-weight-normal;
                }

                &::after {
                    content: '|';
                    padding: 0 3px 0 6px;
                    color: $white;
                }

                &:last-child::after {
                    display: none;
                }
            }
        }
    }
}
