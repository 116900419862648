/* ==============================================================
   MAIN NAV
   - Main nav uses Bootstrap Navbar Component.
   - You can override some Navbar variables in variables.scss
   ============================================================== */

.navbar {
    &.utility-nav {
        padding-top: 0;
        padding-bottom: 0;
        font-size: 12px;

        &.navbar-expand-md {
            display: none;

            @include media-breakpoint-up(md) {
                display: block;
            }
        }

        &.mobile-utility-nav {
            display: block;

            @include media-breakpoint-up(md) {
                display: none;
            }
        }

        .menu-item {
            .nav-link {
                padding: 12px 16px;
            }
        }

        .tagline {
            color: $white;
            font-style: italic;
            font-family: Helvetica, Arial, sans-serif;
            font-size: 14px;
        }
    }
}

aside.navbar-dark {
    width: 70px;
    min-height: 100vh;
}

.sidebar-nav {
    padding-top: 20px;
    flex-direction: column;

    .nav-link {
        padding: 4px 8px;
        color: $white;
        font-size: 24px;
        border-radius: $border-radius;

        &:hover,
        &.active {
            background: $sheen-green;
        }

        .dashboard &.dashboard-link {
            @extend .active;
        }

        .orders &.orders-link {
            @extend .active;
        }

        .customers &.customers-link {
            @extend .active;
        }
    }
}

.bs-tooltip-right {
    .arrow::before {
        border-right-color: $info;
    }
    .tooltip-inner {
        background: $info;
        color: $secondary;
    }
}
