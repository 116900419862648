@import 'node_modules/bootstrap/scss/functions';

// /* ===============================================================
//    VARIABLES
//    - Bootstrap variable overrides AND CUSTOM site variables
//      (not 100% replica of original bootstrap variables stylesheet)
//    - Copy only the Bootstrap variables you want to override and
//      paste below, keeping them in appropriate sections.
//    - Be sure to remove the !default after each variable.
//    - Bootstrap variables file lives in
//      ../node_modules/bootstrap/scss/_variables.scss
// =============================================================== */

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

//
// Color system
//

// // Gray Overrides
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

// // Brand Color Overrides
$beswick-blue: #0072ce;
$dark-imperial-blue: #033f63;
$sheen-green: #97d700;
$blue-lagoon: #b5e2fa;
$cyan: #06bee1;
$portland-orange: #f26430;
$gunmetal: #2a2d34;

// // Brand Colors
$primary: $beswick-blue;
$secondary: $dark-imperial-blue;
$success: $sheen-green;
$info: $blue-lagoon;
$warning: $portland-orange;
// $danger: $pink;

// Body
//
// Settings for the `<body>` element.

$body-bg: $white;
$body-color: $gunmetal;

// Links
//
// Style anchor elements.

$link-color: $primary;
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: none;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-serif: 'Exo', Georgia, 'Times New Roman', Times, serif;
// //** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace: Menlo, Monaco, Consolas, 'Courier New', monospace;
$font-family-base: $font-family-sans-serif;

$font-size-base: 1.125rem; // Assumes the browser default, typically `16px`
$font-size-lg: ($font-size-base * 1.25) !default;
$font-size-sm: ($font-size-base * 0.875) !default;

$h1-font-size: 42px;
$h2-font-size: 36px;
$h3-font-size: 24px;
$h4-font-size: 20px;
$h5-font-size: 16px;
$h6-font-size: 12px;

// //** Font Weights
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-base: $font-weight-normal;

// //** Paragraphs
$paragraph-margin-bottom: 1em;

// //** Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.6666;
// //** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed: floor(($font-size-base * $line-height-base)); // ~20px

// //** By default, this inherits from the `<body>`.
$headings-font-family: $font-family-serif;
$headings-font-weight: $font-weight-normal;
$headings-line-height: 1.1;
$headings-color: inherit !default;

// Components
//
// Define common padding and border radius sizes and more.

// // Custom Padding
$padding-sm: 20px;
$padding: 40px;
$padding-md: 80px;
$padding-lg: 100px;

$line-height-lg: 1.3333333; // extra decimals for Win 8.1 Chrome
$line-height-sm: 1; // 20

// $border-color: $gray-600;
$border-radius: 12px;
// $border-radius-lg: 0.3rem !default;
$border-radius-sm: 8px;
$border-radius-round: 50%;

$component-active-color: $primary;
// $component-active-bg: $white;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
) !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1440px,
) !default;

// Navbar

$navbar-padding-y: 0;
$navbar-padding-x: 0;
$navbar-nav-link-padding-x: 20px;
$navbar-dark-color: $white;
// $navbar-dark-hover-color: $white;
$navbar-light-color: $primary;
$navbar-light-hover-color: $sheen-green;
$navbar-light-active-color: $white;

// Navs

$nav-tabs-border-color: $primary;
$nav-tabs-border-width: 2px;
// $nav-tabs-border-radius:            $border-radius !default;
// $nav-tabs-link-hover-border-color:  $gray-200 $gray-200 $nav-tabs-border-color !default;
$nav-tabs-link-active-color: $black;
$nav-tabs-link-active-bg: $info;
$nav-tabs-link-active-border-color: $primary $primary $nav-tabs-link-active-bg;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.24rem;
$input-btn-padding-x: 1rem;

$input-btn-padding-y-sm: 0.24rem;
$input-btn-padding-x-sm: 1rem;

$input-btn-border-width: 2px;

$btn-border-radius-sm: $border-radius-sm;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-font-weight: $font-weight-bold;

// Badges

$badge-padding-y: 0.15em;

// Forms

// //** Placeholder text color
$input-placeholder-color: #9b9b9b;

// //** `.form-group` margin
$form-group-margin-bottom: 12px;

// //** Text color for `<input>`s
$input-color: #939598;
// //** `<input>` border color
$input-border-color: $primary;

$custom-control-indicator-size: 1.25rem;
$custom-control-indicator-bg: $white;
$custom-control-indicator-checked-bg: $white;
$custom-control-indicator-checked-border-color: $primary;

$custom-checkbox-indicator-border-radius: 2px;

$custom-select-bg-size: 16px 20px; // In pixels because image dimensions
$custom-select-indicator-color: $primary;
$custom-select-indicator: str-replace(
    url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='#{$custom-select-indicator-color}' d='M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z'/%3E%3C/svg%3E"),
    '#',
    '%23'
);

// Breadcrumbs

$breadcrumb-padding-y: 1rem;

$breadcrumb-bg: $dark-imperial-blue;
$breadcrumb-divider-color: $blue-lagoon;
$breadcrumb-active-color: $blue-lagoon;
$breadcrumb-divider: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNi4yNjIgNS41NjZsLTQuMjQ5IDQuMjVhLjc0Ny43NDcgMCAwIDEtMS4wNTkgMGwtLjcwNi0uNzA3YS43NDcuNzQ3IDAgMCAxIDAtMS4wNTlMMy4yNiA1LjA0LjI0OCAyLjAyN2EuNzQ3Ljc0NyAwIDAgMSAwLTEuMDZsLjcwMy0uNzFhLjc0Ny43NDcgMCAwIDEgMS4wNiAwbDQuMjQ4IDQuMjQ4YS43NDguNzQ4IDAgMCAxIC4wMDMgMS4wNjJ6IiBmaWxsPSIjQjVFMkZBIi8+PC9zdmc+');

$breadcrumb-border-radius: 0;

// Cards

// $card-spacer-y: 0.25rem;
// $card-spacer-x: 0.25rem;
$card-border-width: 2px;
// $card-border-radius: $border-radius !default;
$card-border-color: $beswick-blue;
// $card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width}) !default;
$card-cap-bg: $beswick-blue;
// $card-bg: $white !default;

// Alerts
//
// Define alert colors, border radius, and padding.

// $alert-padding-y:                   .75rem !default;
// $alert-padding-x:                   1.25rem !default;
// $alert-margin-bottom:               1rem !default;
// $alert-border-radius: 0;
// $alert-link-font-weight:            $font-weight-bold !default;
// $alert-border-width:                $border-width !default;

$alert-bg-level: 0;
$alert-border-level: 0;
$alert-color-level: 10;

// Pagination

// $pagination-padding-y: 0.5rem !default;
// $pagination-padding-x: 0.75rem !default;
// $pagination-padding-y-sm: 0.25rem !default;
// $pagination-padding-x-sm: 0.5rem !default;
// $pagination-padding-y-lg: 0.75rem !default;
// $pagination-padding-x-lg: 1.5rem !default;
// $pagination-line-height: 1.25 !default;

$pagination-color: $link-color;
$pagination-bg: transparent;
$pagination-border-width: 0;
// $pagination-border-color: $gray-300 !default;

// $pagination-focus-box-shadow: $input-btn-focus-box-shadow !default;
// $pagination-focus-outline: 0 !default;

// $pagination-hover-color: $link-hover-color !default;
// $pagination-hover-bg: $gray-200 !default;
// $pagination-hover-border-color: $gray-300 !default;

$pagination-active-color: $body-color;
$pagination-active-bg: transparent;
// $pagination-active-border-color: $pagination-active-bg !default;

// $pagination-disabled-color: $gray-600 !default;
// $pagination-disabled-bg: $white !default;
// $pagination-disabled-border-color: $gray-300 !default;

// Tooltips

$tooltip-font-size: 11px;
$tooltip-max-width: 150px;
$tooltip-color: $white;
$tooltip-bg: $dark-imperial-blue;
// $tooltip-border-radius:             $border-radius !default;
$tooltip-opacity: 1;
$tooltip-padding-y: 0.5rem;
// $tooltip-padding-x:                 .5rem !default;
// $tooltip-margin:                    0 !default;

// $tooltip-arrow-width:               .8rem !default;
// $tooltip-arrow-height:              .4rem !default;
$tooltip-arrow-color: $dark-imperial-blue;

// Modals
$modal-inner-padding: 1rem !default;
$modal-header-padding: $modal-inner-padding;
$modal-header-border-width: 0;
