// Cart
.shopping-cart {
    .table-buttons {
        h3 {
            font-weight: $font-weight-bold;
            color: $body-color;
        }
    }

    .alert {
        color: $white;
    }

    input#parts_list_item_quantity {
        max-width: 90px;
    }
}
