/* ===============================================================
    Cards
	- Bootstrap Card Overrides
   =============================================================== */
.card {
    overflow: hidden;

    .card-text {
        font-weight: $font-weight-bold;
    }

    .card-body {
        font-size: 14px;
    }

    .card-footer {
        background: $blue-lagoon;
    }

    table th {
        border: 0;
    }
}

.order-summary .card-header,
.alert {
    .btn {
        .svg-inline--fa {
            color: $body-color;
            font-size: 24px;
            @include transition(transform 0.2s ease);
        }

        &.collapsed {
            .svg-inline--fa {
                transform: rotate(-180deg);
            }
        }
    }
}

.order-summary .card {
    border-color: $sheen-green;

    .card-header {
        background: $sheen-green;
        border-color: $sheen-green;

        h2 {
            font-size: 20px;
        }
    }
}
