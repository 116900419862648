// Breadcrumbs
.breadcrumb-wrapper {
    background: $dark-imperial-blue;

    .breadcrumb {
        margin-bottom: 0;
        display: none;

        @include media-breakpoint-up(md) {
            display: flex;
        }

        .breadcrumb-item {
            color: $blue-lagoon;
            font-size: 14px;

            a {
                color: $blue-lagoon;

                &:hover {
                    color: $sheen-green;
                }
            }
        }
    }

    div.d-flex > div {
        padding: 12px 0;
        margin-right: 8px;
        display: flex;
        align-items: center;

        a {
            color: $blue-lagoon;

            &:last-child {
                margin-left: 16px;

                @include media-breakpoint-up(lg) {
                    margin-left: 30px;
                }
            }

            .svg-inline--fa {
                font-size: 20px;
                margin-left: 10px;
            }
        }
    }

    .cart {
        margin-right: -8px;
        font-size: 14px;

        .badge-pill {
            font-size: 12px;
            display: inline;
            vertical-align: top;
            left: -4px;
            position: relative;
        }
    }
}
