/* ===============================================================
    BACKGROUNDS
	- Consistent and common styles for container backgrounds
	- The intended real world use for these selectors is for
	  creating a quick, nice looking page (that has not been provided
	  with a design) in Drupal via the wysiwyg editor.
	- When creating a page that DOES have a design, it is preferable
      to assign variables to the elements rather than use these classes.
	- ** RENAME color selectors with your custom color
	  variable names
   =============================================================== */

.bg-light {
    background: $blue-lagoon !important;
}
.bg-dark {
    background: theme-color('secondary') !important;
}

/* Brand Background Colors */
.bg-brand-primary,
.bg-purple {
    background: theme-color('primary');
}
.bg-brand-success,
.bg-magenta {
    background: theme-color('success');
}
.bg-brand-info,
.bg-lilac {
    background: theme-color('info');
}
.bg-brand-warning,
.bg-orange {
    background: theme-color('warning');
}
.bg-brand-danger,
.bg-pink {
    background: theme-color('danger');
}

/* Gray Background Colors */
.bg-gray-base {
    background: color('500');
}
.bg-gray-darker {
    background: gray('900');
}
.bg-gray-dark {
    background: gray('700');
}
.bg-gray {
    background: color('gray');
}
.bg-gray-light {
    background: gray('300');
}
.bg-gray-lighter {
    background: gray('100');
}
