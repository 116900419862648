/* ==============================================================
   BASE
   - Styles for default/global HTML elements (ex. body, a, h1-h6)
   - ALWAYS check to see if you can achieve your styles first by
     overriding bootstrap variables
   - MISC items can also go here (not ideal)
============================================================== */

/* Smoother fonts */
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    flex-wrap: wrap;
}

a {
    font-weight: $font-weight-bold;
    @include transition(all 0.15s ease-in-out);
}

/* Responsive images */
img {
    max-width: 100%;
    height: auto;
    width: auto;
}

/* Responsive Video */
iframe,
video {
    max-width: 100%;
}

/* Make oembeds responsive */
.entry-oembed {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    max-width: 100%;
    height: auto;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
    &:first-child {
        margin-top: 0;
    }
}

/* Paragraphs */
p {
    margin-bottom: $paragraph-margin-bottom;
}

.table thead {
    font-family: $font-family-serif;
    font-size: 14px;
}

#main {
    flex: 1;
    margin-bottom: 124px;
    min-height: calc(100vh - 230px);

    @include media-breakpoint-up(md) {
        margin-bottom: 65px;
    }

    @include media-breakpoint-up(xl) {
        margin-bottom: 50px;
    }

    .is-admin & {
        margin-bottom: 0;
        // padding-left: 70px;

        @include media-breakpoint-up(xl) {
            > .container {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
}

.container {
    @include media-breakpoint-up(xl) {
        padding-left: 40px;
        padding-right: 40px;
    }
}

.sticky {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;

    @include media-breakpoint-down(sm) {
        box-shadow: none !important;
        border-radius: 0;

        .card-header {
            border-radius: 0;
        }
    }

    @include media-breakpoint-up(md) {
        width: 270px;
        position: absolute;
        bottom: initial;
        left: initial;
        right: $grid-gutter-width / 2;
        z-index: 1;
    }

    @include media-breakpoint-up(lg) {
        width: 290px;
    }

    @include media-breakpoint-up(xl) {
        width: 372px;
    }
}
