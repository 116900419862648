// Checkout
.checkout-page {
    .alert {
        border-radius: 0;

        h2 {
            font-size: 20px;
        }
    }

    .order-summary {
        .btn {
            @extend .btn-block;
        }
    }
}
