/* ===============================================================
    TYPE
	- Styles that can be applied to text
	- The intended real world use for these selectors is for
	  creating a quick, nice looking page (that has not been provided
	  with a design) in Drupal via the wysiwyg editor.
	- When creating a page that DOES have a design, it is preferable
      to assign variables to the elements rather than use these classes.
	- ** RENAME colo selectors with your custom color
	  variable names
   =============================================================== */

h2,
.h2,
h4,
.h4,
h5,
.h5 {
    font-weight: $font-weight-bold;
}

h3,
.h3 {
    color: $primary;
    line-height: 1.5;
    font-weight: $font-weight-normal;
}

h4,
.h4 {
    color: $dark-imperial-blue;
}

.entry-content ul {
    padding-left: 1.5em;
    margin: 2em 0 2em 1em;
    list-style: none;

    li {
        margin: 0.5em 0;
        text-indent: -1.5em;

        &:before {
            content: ' ';
            margin-right: 0.5em;
            display: inline-block;
            width: 0.8em;
            height: 0.8em;
            text-indent: 0;

            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjA4IiBoZWlnaHQ9IjMyMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMjAwLjMgMTc4bC0xMzYgMTM2Yy05LjQgOS40LTI0LjYgOS40LTMzLjkgMEw3LjggMjkxLjRjLTkuNC05LjQtOS40LTI0LjYgMC0zMy45bDk2LjQtOTYuNEw3LjggNjQuN2MtOS40LTkuNC05LjQtMjQuNiAwLTMzLjlMMzAuMyA4YzkuNC05LjQgMjQuNi05LjQgMzMuOSAwbDEzNiAxMzZjOS41IDkuNCA5LjUgMjQuNi4xIDM0eiIgZmlsbD0iIzAwNzJDRSIvPjwvc3ZnPg==');
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}

/* Text Color */
.txt-brand-primary,
.txt-purple {
    color: theme-color('primary');
}
.txt-brand-success,
.txt-magenta {
    color: theme-color('success');
}
.txt-brand-info,
.txt-lilac {
    color: theme-color('info');
}
.txt-brand-warning,
.txt-orange {
    color: theme-color('warning');
}
.txt-brand-danger,
.txt-pink {
    color: theme-color('danger');
}
.txt-white {
    color: $white;
}

/* Text Color - Links */
a.txt-brand-primary:hover,
a.txt-purple:hover {
    color: darken(theme-color('primary'), 15%);
}
a.txt-brand-success:hover,
a.txt-magenta:hover {
    color: darken(theme-color('success'), 15%);
}
a.txt-brand-info:hover,
a.txt-lilac:hover {
    color: darken(theme-color('info'), 15%);
}
a.txt-brand-warning:hover,
a.txt-orange:hover {
    color: darken(theme-color('warning'), 15%);
}
a.txt-brand-danger:hover,
a.txt-pink:hover {
    color: darken(theme-color('danger'), 15%);
}

/* Font Weights */
.weight-light {
    font-weight: $font-weight-light;
}
.weight-normal {
    font-weight: $font-weight-normal;
}
.weight-bold {
    font-weight: $font-weight-bold;
}

// Text Sizes */
.txt-sm {
    font-size: $font-size-sm;
}
.txt-base {
    font-size: $font-size-base;
}
.txt-lg {
    font-size: $font-size-lg;
}

/* Font Families */
.font-sans-serif {
    font-family: $font-family-sans-serif;
}
.font-serif {
    font-family: $font-family-serif;
}

/* Line Heights */
.line-height-sm {
    line-height: $headings-line-height;
}
.line-height-base {
    line-height: $line-height-base;
}
