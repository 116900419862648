img.alignright {
	float: right;
	margin: 0 0 15px 15px;
}
img.alignleft {
	float: left;
	margin: 0 15px 15px 0;
}
img.aligncenter {
    margin: 0 auto;
    display: block;
}